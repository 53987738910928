<template>
  <div class="relative z-10 space-y-12 seeroo-media-page">
    <div class="media-list">
<!--      <div v-if="page == 1 && isLoadingPage" class="media-loader-wrap">-->
<!--        <ProgressSpinner/>-->
<!--      </div>-->

      <div  class="media-grid-wrapper" >
        <div class="seeroo-grid grid gap-4 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-2 cs-style-3" >
          <div v-for="item in likedMediaList" :key="item.id" class="media-item" style="max-height:180px; margin-top:60px">
            <router-link :to="`/theatre/detail/${item.id}`" class="media-cover">
              <a href="javascript:void(0)" class="card__play">
                <i class="isax isax-play"></i>
              </a>
              <p class="media-cover-title">{{ item.title }}</p>
            </router-link>
            <span v-if="item.is_free" class="card__rate card__rate--green price">Үнэгүй</span>
            <img :src="Poster"  v-lazy:background-image="imageUrl(item.poster_hz,true)" class="poster rounded-md"
                 style=" background-size:cover;background-position: center;width: 100%; height: 180px;"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="hasMore && !isLoadingPage" class="pagination">
      <ProgressSpinner v-if="isLoadingMore" style="width:50px;height:50px" strokeWidth="3" animationDuration="1s"/>
      <Button v-else type="button" @click="getEpisodes">
        <span>Цааш</span>
        <i class="pi pi-angle-double-down"></i>
      </Button>
    </div>
  </div>
</template>

<script>
import Poster from "../../assets/poster.png";
import {imageUrl} from "../../utils";
import {setModalActive} from "../../store";
import useDevice from "../../hooks/useDevice";

export default {
  setup() {
    const {isDesktop} = useDevice();
    return {isDesktop};
  },

  data() {
    return {
      isLoadingPage: true,
      isLoadingMore: false,
      // cat: null,
      hasMore: false,
      page: 1,
      // likedEpisode: [],
      Poster
    }
  },

  computed: {
    likedMediaList() {
      console.log("dcsdcdscsd------->");
      console.log("front media list", this.$store.state['likedMediaList']);
      return this.$store.state['likedMediaList'];
    }
  },

  created() {
    this.getEpisodes();
    // this.getLikedMedia();
  },

  methods: {
    imageUrl,
    handleClick(animeID) {
      this.$router.replace({query: {media: animeID}});
      setModalActive(true);
    },
    // getLikedMedia(payload) {
    //   api.get(`/api/m/anime/list/2?page=${payload.vm.page}&t=liked`).then(({data}) => {
    //     this.likedEpisode = data.data;
    //     console.log("front data---->", this.likedEpisode)
    //     console.log(" data---->", data)
    //     setTimeout(() => {
    //       payload.vm.isLoading = false;
    //       payload.vm.isLoadingMore = false;
    //     }, 1000);
    //
    //     if (data.current_page === data.last_page) {
    //       payload.vm.hasMore = false;
    //     } else {
    //       payload.vm.hasMore = true;
    //       payload.vm.page = data.current_page + 1;
    //     }
    //   })
    // },

    getEpisodes() {
      this.isLoadingMore = true;
      this.$store.dispatch('getLikedMedia', {vm: this});
    }
  }
}
;
</script>
